import React, { useState, useEffect } from "react";
import { db } from "./firebaseConfig"; // Adjust the import path as necessary
import { doc, getDoc } from "firebase/firestore";

const AboutUs = () => {
  const [aboutText, setAboutText] = useState("");

  useEffect(() => {
    const fetchAboutText = async () => {
      const docRef = doc(db, "aboutUsContent", "mainText"); // Adjust with your actual collection and document ID
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        setAboutText(docSnap.data().text); // Assuming the field containing the text is named 'text'
      } else {
        console.log("No such document!");
      }
    };

    fetchAboutText();
  }, []);

  return (
    <>
      <section className="container main-hero-container p-4">
        <p>{aboutText}</p>
      </section>
    </>
  );
};

export default AboutUs;
