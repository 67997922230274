import React, { useEffect, useState } from 'react';
import { db } from './firebaseConfig'; // Make sure you have this file set up for your Firebase config
import { doc, getDoc } from 'firebase/firestore';

const Footer = () => {
    const [footerData, setFooterData] = useState({
        phone1: '',
        phone2: '',
        address: '',
        mapSrc: '',
    });

    useEffect(() => {
        const fetchFooterData = async () => {
            const docRef = doc(db, 'config', 'footerInfo'); // Adjust 'yourDocumentId' as necessary
            const docSnap = await getDoc(docRef);

            if (docSnap.exists()) {
                setFooterData(docSnap.data());
            } else {
                console.log('No footer data found!');
            }
        };

        fetchFooterData();
    }, []);

    return (
        <footer className="footer-container-main">
            <div className="footer-container">
                <div className="box">
                    <h3>Contact Us</h3>
                    <p>Phone: {footerData.phone1} <br/>{footerData.phone2} </p>
                </div>
                <div className="box">
                    <h3>Address</h3>
                    <p>{footerData.address}</p>
                </div>
                <div className="box">
                    <iframe
                        className="map-lyt"
                        src={footerData.mapSrc}
                        allowFullScreen=""
                        loading="lazy"
                        referrerPolicy="no-referrer-when-downgrade"
                    ></iframe>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
