import React, { useEffect, useState } from "react";
import { db } from './firebaseConfig'; // Adjust the path as necessary
import { collection, getDocs, query, orderBy, doc, getDoc } from "firebase/firestore";

const Donation = () => {
  const [donationTypes, setDonationTypes] = useState([]);
  const [buttonConfig, setButtonConfig] = useState({ visible: false, text: "None", link: "#" });

  useEffect(() => {
    const fetchDonationTypes = async () => {
      const q = query(collection(db, "donationTypes"), orderBy("index"));
      const querySnapshot = await getDocs(q);
      const types = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setDonationTypes(types);
    };

    const fetchButtonConfig = async () => {
      const docRef = doc(db, "DonationButton", "donationButton");
      const docSnap = await getDoc(docRef);
    
      if (docSnap.exists()) {
        setButtonConfig(docSnap.data());
      } else {
        console.log("No donation button config found!");
      }
    };
    
  

    fetchDonationTypes();
    fetchButtonConfig();
  }, []);

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <h2>Donation</h2>
            <div className="subject-container">
              {donationTypes.map((type) => (
                <div key={type.id} className="subject-section">
                  <h4>{type.title}</h4>
                  <p>{type.description}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
        {buttonConfig.visible && (
          <button type="button" className="btn btn-primary" onClick={() => window.open(buttonConfig.link, "_blank")}>
            {buttonConfig.text}
          </button>
        )}
      </div>
    </>
  );
};

export default Donation;
