import React from "react";
import Navbar from "./navbar";
import Header from "./Header";
import AboutUs from "./aboutUs";
import IslamicCalendar from "./Calender";
import Footer from "./Footer";
import GalleryApp from "./Gallery";
import Detail from "./Detail";
import Donation from "./Donation";
import MoreData from "./MoreData";
import Important from "./Important";

const App = () => {
  return(<>
  <div className="bodyClass">
  <Navbar/>
  <div style={{height:"50px", width:"100%"}} ></div>
  <div id="home"><Header/></div>
  <Important/>
  <AboutUs/>
  <div id="Event"><MoreData/></div>
  <div className="container"><h2>Gallery</h2></div>
  <div id="Gallery"><GalleryApp/></div>
  <br></br><br></br><br></br>
  <div id="About"><Detail/></div>
  <br></br>
  <div className="container divider-span"></div>
  <br></br>
  <div id="Donation"><Donation/></div>
  <br></br><br></br><br></br>
  <div className="container"><h2>Islamic Calendar</h2></div>
  <div id="Islamic-Calender"><IslamicCalendar/></div>
  <div id="Contact"><Footer/></div>
  </div>
  </>
  );
};
export default App;
