import React, { useState, useEffect } from 'react';

const months = [
  { value: 1, label: 'January' },
  { value: 2, label: 'February' },
  { value: 3, label: 'March' },
  { value: 4, label: 'April' },
  { value: 5, label: 'May' },
  { value: 6, label: 'June' },
  { value: 7, label: 'July' },
  { value: 8, label: 'August' },
  { value: 9, label: 'September' },
  { value: 10, label: 'October' },
  { value: 11, label: 'November' },
  { value: 12, label: 'December' },
];

function generateYearOptions(startYear, endYear) {
  const years = [];
  for (let year = startYear; year <= endYear; year++) {
    years.push(year);
  }
  return years;
}

const years = generateYearOptions(1990, 2070);

const IslamicCalendar = () => {
  const [month, setMonth] = useState(new Date().getMonth() + 1);
  const [year, setYear] = useState(new Date().getFullYear());
  const [calendarData, setCalendarData] = useState([]);
  const [today, setToday] = useState(new Date());

  useEffect(() => {
    const fetchCalendarData = async () => {
      const url = `https://api.aladhan.com/v1/gToHCalendar/${month}/${year}`;      
      try {
        const response = await fetch(url);
        const data = await response.json();
        setCalendarData(data.data);
      } catch (error) {
        console.error('Error fetching Islamic calendar data:', error);
      }
    };

    fetchCalendarData();
  }, [month, year]);

  // Automatically update the highlighted current date at midnight
  useEffect(() => {
    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(tomorrow.getDate() + 1);
    tomorrow.setHours(0, 0, 0, 0);

    const msUntilMidnight = tomorrow - today;
    const timer = setTimeout(() => {
      setToday(new Date());
    }, msUntilMidnight);

    return () => clearTimeout(timer);
  }, [today]);

  const handleReset = () => {
    setMonth(new Date().getMonth() + 1);
    setYear(new Date().getFullYear());
  };

  const weekDays = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];

  const formatIslamicDate = (islamicDate) => `${islamicDate.month.en}-${islamicDate.year}`;

  return (
    <div className="calendar container">
      <div className="calendar-container">
        <div className="header">
          <div className="heading-calendar">
            <span className="device-txt">{new Date().toLocaleDateString('en-GB', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })}</span>
            <span className="device-txt">{calendarData.length > 0 ? formatIslamicDate(calendarData[0].hijri) : ''}</span>
          </div>
          <div className="controls">
            <select className="custom-select" value={month} onChange={(e) => setMonth(Number(e.target.value))}>
              {months.map((m) => (
                <option key={m.value} value={m.value}>{m.label}</option>
              ))}
            </select>
            <select className="custom-select" value={year} onChange={(e) => setYear(Number(e.target.value))}>
              {years.map((y) => (
                <option key={y} value={y}>{y}</option>
              ))}
            </select>
            <button className="reset-btn" onClick={handleReset}>Reset Calendar</button>
          </div>
        </div>
        <div className="weekdays">
          {weekDays.map((day, index) => (
            <div key={index} className="weekday">{day}</div>
          ))}
        </div>
        <div className="calendar-grid">
          {calendarData.map((day, index) => (
            <div key={index} className={`day ${new Date(day.gregorian.date).toDateString() === today.toDateString() ? 'today' : ''}`}>
              <span className="gregorian-date">{day.gregorian.day}<br /></span>
              <span className="hijri-date">{day.hijri.day}</span><br />
              <span className="hijri-day-name">{day.hijri.weekday.en}</span>
            </div>
          ))}
        </div>
      </div>
      <div className="special-days">
        <h3 className="special-days-txt">Special Islamic Days</h3>
        {calendarData.filter(day => day.hijri.holidays && day.hijri.holidays.length > 0).map((day, index) => (
          <div key={index} className="special-day">
            <span className="sd-name">{day.hijri.holidays.join(', ')}</span>
            <span className="sd-geo-date">{day.gregorian.date}</span>
            <span className="sd-hijri-date">{day.hijri.date}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default IslamicCalendar;
