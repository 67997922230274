import React, { useEffect, useState } from "react";
import { db } from "./firebaseConfig";
import { collection, getDocs } from "firebase/firestore";


const Header = () => {
  const [images, setImages] = useState([]);

  useEffect(() => {
    const fetchImages = async () => {
      const imagesCollectionRef = collection(db, "carouselImages");
      const data = await getDocs(imagesCollectionRef);
      setImages(data.docs.map(doc => ({ ...doc.data(), id: doc.id })));
    };
  
    fetchImages();
  }, []);

  return (
    <>
      <header className="main-header">

      <div class="container mb-2">
    <div class="row align-items-center"> 
        <div class="col-6">
            <h1 class="text-left">Madarsa Jamia Hamidia Azhar Ul Uloom</h1>
        </div>
        <div class="col-6 d-flex justify-content-end"> 
            <div class="language-options">
                <input type="radio" id="englishOption" name="language" hidden />
                <label for="englishOption" class="language-label">Eng</label>

                <input type="radio" id="hindiOption" name="language" hidden />
                <label for="hindiOption" class="language-label">Hin</label>

                <input type="radio" id="urduOption" name="language" hidden />
                <label for="urduOption" class="language-label">Urd</label>
            </div>
        </div>
    </div>
</div>

        <section className="container main-hero-container custom-div-width">
          <div
            id="carouselExampleAutoplaying"
            className="carousel slide"
            data-bs-ride="carousel"
          >
            <div className="carousel-indicators">
              {images.map((_, index) => (
                <button
                  key={index}
                  type="button"
                  data-bs-target="#carouselExampleAutoplaying"
                  data-bs-slide-to={index}
                  className={index === 0 ? "active" : ""}
                  aria-current={index === 0 ? "true" : undefined}
                  aria-label={`Slide ${index + 1}`}
                ></button>
              ))}
            </div>

            <div className="carousel-inner rounded">
              {images.map((img, index) => (
                <div key={img.id} className={`carousel-item ${index === 0 ? "active" : ""} rounded`}>
                  <img
                    src={img.url} //documents have a `url` field fir images
                    className="d-block w-100 img-fluid img-thumbnail"
                    alt="..."
                  />
                </div>
              ))}
            </div>

            <button
              className="carousel-control-prev"
              type="button"
              data-bs-target="#carouselExampleAutoplaying"
              data-bs-slide="prev"
            >
              <span className="carousel-control-prev-icon" aria-hidden="true"></span>
              <span className="visually-hidden">Previous</span>
            </button>
            <button
              className="carousel-control-next"
              type="button"
              data-bs-target="#carouselExampleAutoplaying"
              data-bs-slide="next"
            >
              <span className="carousel-control-next-icon" aria-hidden="true"></span>
              <span className="visually-hidden">Next</span>
            </button>
          </div>
        </section>
      </header>
    </>
  );
};

export default Header;
