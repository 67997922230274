import React, { useEffect, useState } from "react";
import { db } from "./firebaseConfig"; // Adjust the path as necessary
import { doc, getDoc } from "firebase/firestore";

const MoreData = () => {
  const [data, setData] = useState({
    imageUrl: "",
    showDownload: false,
    showSection: false,
  });

  useEffect(() => {
    const fetchData = async () => {
      const docRef = doc(db, "Event", "lZZnBH8ggtKLviKDnrfW");
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        const docData = docSnap.data();
        setData({
          imageUrl: docData.imageUrl,
          showDownload: docData.showDownload,
          showSection: docData.showSection,
          downloadData: docData.downloadData,
        });
      } else {
        console.log("No such document!");
      }
    };

    fetchData();
  }, []);

  // Conditional rendering based on showSection
  if (!data.showSection) return null;

  return (
    <>
      <div className="container">
        <img className="table-img" src={data.imageUrl} alt="Table" />
        {data.showDownload && (
          <div className="download-container">
            <button
              className="btn btn-outline-success calender-download"
              onClick={() => {
                const link = document.createElement("a");
                link.href = data.downloadData; // Ensure this is the URL to the file you want to download.
                link.download = "Download"; // This attribute can specify the filename you want the file to have.
                document.body.appendChild(link); // Append to document
                link.click(); // Programmatically click the link to trigger the download
                document.body.removeChild(link); // Remove the link from document
              }}
            >
              ⬇ Download
            </button>
          </div>
        )}
      </div>
    </>
  );
};

export default MoreData;
