import React, { useState, useEffect } from "react";
import { db } from "./firebaseConfig"; // Ensure this points to your Firebase config file
import { doc, getDoc } from "firebase/firestore";

const Navbar = () => {
  const [show, setShow] = useState(false);
  const [iconUrl, setIconUrl] = useState("");

  // Function to handle link click
  const handleNavLinkClick = () => {
    setShow(false); // Set show to false, collapsing the navbar
  };

  useEffect(() => {
    const fetchIconUrl = async () => {
      const docRef = doc(db, "config", "navbarIcon"); // Example path
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        setIconUrl(docSnap.data().iconUrl); // Make sure "iconUrl" matches your field name
      } else {
        console.log("No icon found!");
      }
    };

    fetchIconUrl();
  }, []);

  return (
    <>
      <section>
        <nav className="navbar navbar-expand-lg fixed-top navbar-bg-color">
          <div className="container">
            {iconUrl && (
              <a className="navbar-brand text-white" href="#">
                <img
                  src={iconUrl}
                  alt="Navbar Icon"
                  style={{ height: "60px", marginLeft: "10px" }}
                />
              </a>
            )}
            <button
            style={{backgroundColor: "rgba(255, 255, 255, 0.5)"}}
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
              onClick={() => setShow(!show)}
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className={`collapse navbar-collapse ${show ? "show" : ""}`}>
              <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
                <li class="nav-item">
                  <a
                    class="nav-link active text-white"
                    aria-current="page"
                    onClick={handleNavLinkClick}
                    href="#"
                  >
                    Home
                  </a>
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link text-white"
                    onClick={handleNavLinkClick}
                    href="#Event"
                  >
                    Event
                  </a>
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link text-white"
                    onClick={handleNavLinkClick}
                    href="#Gallery"
                  >
                    Gallery
                  </a>
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link text-white"
                    onClick={handleNavLinkClick}
                    href="#About"
                  >
                    About
                  </a>
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link text-white"
                    onClick={handleNavLinkClick}
                    href="#Donation"
                  >
                    Donation
                  </a>
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link text-white"
                    onClick={handleNavLinkClick}
                    href="#Islamic-Calender"
                  >
                    Islamic Calender
                  </a>
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link text-white"
                    onClick={handleNavLinkClick}
                    href="#Contact"
                  >
                    Contact Us
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </section>
    </>
  );
};

export default Navbar;
