import React, { useState, useEffect } from 'react';
import ReactModal from 'react-modal';
import { db } from './firebaseConfig'; // Adjust the import path as necessary
import { collection, query, getDocs, orderBy } from 'firebase/firestore';

const Gallery = () => {
    const [images, setImages] = useState([]);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);
    const [modalStyle, setModalStyle] = useState({});

    useEffect(() => {
        const fetchImages = async () => {
            const q = query(collection(db, "galleryImages"), orderBy("index"));
            const querySnapshot = await getDocs(q);
            const fetchedImages = querySnapshot.docs.map(doc => doc.data());
            setImages(fetchedImages);
        };

        fetchImages();
    }, []);

    const openModal = (image) => {
        setSelectedImage(image);

        const img = new Image();
        img.onload = () => {
            const maxWidthPercentage = 70;
            const viewportWidth = window.innerWidth;
            const isMobile = viewportWidth < 768;
            const maxModalWidth = isMobile ? viewportWidth : viewportWidth * (maxWidthPercentage / 100);
            const modalWidth = Math.min(img.width, maxModalWidth);

            setModalStyle({
                content: {
                    width: isMobile ? '100%' : `${modalWidth}px`,
                    height: 'auto',
                    top: '50%',
                    left: '50%',
                    right: 'auto',
                    bottom: 'auto',
                    marginRight: '-50%',
                    transform: 'translate(-50%, -50%)',
                    border: 'none',
                    background: 'none',
                    overflow: 'visible',
                    padding: 0,
                },
                overlay: {
                    backgroundColor: 'rgba(0, 0, 0, 0.8)'
                }
            });
        };
        img.src = image.src;

        setModalIsOpen(true);
    };

    const closeModal = () => {
        setModalIsOpen(false);
    };

    return (
        <div className="gallery container mb-6">
            {images.map((image, index) => (
                <img
                    key={index}
                    src={image.src}
                    alt={image.alt}
                    className={`gallery-image ${image.orientation}`}
                    onClick={() => openModal(image)}
                />
            ))}
            <ReactModal 
                isOpen={modalIsOpen} 
                onRequestClose={closeModal} 
                contentLabel="Image Modal"
                style={modalStyle}
            >
                <div style={{
                    display: 'flex',
                    flexDirection: 'column', 
                    justifyContent: 'center', 
                    alignItems: 'center', 
                    height: '100vh',
                    position: 'relative',
                }}>
                    <img 
                        src={selectedImage?.src} 
                        alt={selectedImage?.alt} 
                        style={{
                            maxWidth: '100%',  
                            maxHeight: '100vh',
                            objectFit: 'contain',
                        }} 
                    />
                    <button 
                        onClick={closeModal}
                        style={{
                            border: 'none',
                            background: '#000000',
                            color: 'white',
                            borderRadius: '20px',
                            padding: '10px 20px',
                            fontSize: '16px',
                            cursor: 'pointer',
                            outline: 'none',
                        }}
                    >
                        Close
                    </button>
                </div>
            </ReactModal>
        </div>
    );
};

export default Gallery;
