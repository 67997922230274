import React, { useEffect, useState } from "react";
import { db } from './firebaseConfig'; // Ensure this points to your Firebase config file
import { collection, getDocs, doc, getDoc, orderBy } from "firebase/firestore";

const Detail = () => {
  const [subjects, setSubjects] = useState([]);
  const [imageURL, setImageURL] = useState('');
  const [title, setTitle] = useState('');

  useEffect(() => {
    // Fetch subjects
    const fetchSubjects = async () => {
      const querySnapshot = await getDocs(collection(db, "Subjects"), orderBy("index"));
      const subjectsData = querySnapshot.docs.map(doc => doc.data());
      setSubjects(subjectsData);
    };

    // Fetch image URL
    const fetchImageURL = async () => {
      const docRef = doc(db, "Sub_Table_Image", "imageDoc"); // Adjust "settings" and "imageDoc" accordingly
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        setImageURL(docSnap.data().url); // Adjust .url according to your field name
        setTitle(docSnap.data().title)
      } else {
        console.log("No image document found!");
      }
    };

    fetchSubjects();
    fetchImageURL();
  }, []);

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
              <h2>{title}</h2>
              <div className="subject-container">
                {subjects.map((subject, index) => (
                  <div key={index} className="subject-section">
                    <h4>{subject.title}</h4>
                    <p>{subject.description}</p>
                  </div>
                ))}
              </div>
          </div>
          <div className="col-lg-6">
            {imageURL && <img className="table-img" src={imageURL} alt="Educational Table"/>}
          </div>
        </div>
      </div>
    </>
  );
};

export default Detail;
