import React, { useEffect, useState } from "react";
import { db } from "./firebaseConfig"; // Adjust the path to your Firebase config file
import { doc, getDoc } from "firebase/firestore";

const Important = () => {
  const [importantMessage, setImportantMessage] = useState("");
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const fetchImportantMessage = async () => {
      // Reference to the document containing the important message and visibility flag
      const docRef = doc(db, "config", "importantMessage"); // Adjust "config" and "importantMessage" as necessary

      const docSnap = await getDoc(docRef);

      if (docSnap.exists() && docSnap.data().isVisible) {
        // Only proceed if the document exists and the message is marked as visible
        setImportantMessage(docSnap.data().text); // Assume your document has a field named 'text'
        setIsVisible(true); // Only set to true if document exists and isVisible is true
      } else {
        console.log("No visible important message or document does not exist.");
        setIsVisible(false); // Ensure component knows no message is to be displayed
      }
    };

    fetchImportantMessage();
  }, []);

  return (
    <>
      {isVisible && (
        <div className="container text-center text-info">
          <br />
          <marquee>{importantMessage}</marquee>
        </div>
      )}
    </>
  );
};

export default Important;
